import  Home from "./components/Home";
import AddSheetForm from "./components/AddSheetForm";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    element: <AddSheetForm key={true} isComputeSheet={true} />,
    path: "/add-compute-sheets"
  },
  {
    element: <AddSheetForm key={false} isComputeSheet={false} />,
    path: "/add-no-compute-sheets"
  }
];

export default AppRoutes;
