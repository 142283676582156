import React, {useState} from "react";
import SheetsTable from "./SheetsTable";
import AddSheetForm from "./AddSheetForm";

const Home = () => {
  return (
    <>
      <SheetsTable></SheetsTable>
    </>
  );
};

export default Home;
