import React, { useEffect, useState } from "react";
import { Button, Tooltip, Form, InputGroup, Col, Container, Row, Spinner, OverlayTrigger } from "react-bootstrap";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BsCheckAll } from "react-icons/bs";
import { IoIosWarning } from "react-icons/io";



const accessibilityStatus = {
    checking: "Checking",
    valid: "Valid",
    invalid: "Invalid",
}
export default function AddSheetForm({ isComputeSheet }) {
    const [isLoading, setLoading] = useState(false);
    const [sheets, setSheets] = useState([{}]);
    const [resultSheet, setResultSheet] = useState({});
    const timeoutRef = React.useRef(new Map());

    useEffect(() => {
        setLoading(true)
        fetch(`spreadsheets/all-sheets?isComputeSheet=${isComputeSheet}`)
            .then((response) => response.json())
            .then((data) => {
                setResultSheet(data.filter(x => x.type === "Result")[0])
                const sheets = data.filter(x => x.type === "Source").map(x => ({
                    link: x.link,
                    status: x.status,
                    spreadSheetName: x.spreadSheetName,
                    accessibilityStatus: x.accessibilityStatus
                }))
                setSheets(sheets.length > 0 ? sheets : [{}])
            })
            .finally(() => setLoading(false));
    }, [isComputeSheet]);

    const handleAddMoreSheet = () => {
        setSheets([...sheets, {}])
    }
    const handleSubmitSourceSheets = (sheets) => {
        const nonEmptySheets = sheets.filter(sheet => !!sheet.link && !!sheet.spreadSheetName)
        setLoading(true);

        const submitPromise =
            fetch(`spreadsheets/add-sheets?isComputeSheet=${isComputeSheet}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(nonEmptySheets)
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("An error occurred");
                    }
                })
                .finally(() => {
                    setLoading(false);
                })

        toast.promise(
            submitPromise,
            {
                pending: 'Adding sheets...',
                success: 'Sheets added successfully 🎉',
                error: 'An error occurred 🤯'
            }
        )

    }

    const handleSubmitResultSheet = () => {
        const addResultSheetPromise = fetch(`spreadsheets/add-result-sheet?isComputeSheet=${isComputeSheet}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(resultSheet)
        }).then(response => {
            if (!response.ok) {
                throw new Error("An error occurred");
            }

            return Promise.resolve();
        })

        setLoading(true)

        toast.promise(addResultSheetPromise, {
            pending: 'Adding result sheet...',
            success: 'Result sheet added successfully 🎉',
            error: 'An error occurred 🤯'
        }).finally(() => {
            setLoading(false);
        })
    }

    function handleRemoveSheet(index) {
        setSheets(current => current.filter((_, i) => i !== index))
    }

    function handleConcatenate() {
        if (!resultSheet) {
            toast.error("No Result Sheet found. Please add a result sheet first.")
            return
        }
        setLoading(true);

        const concatenatePromise = fetch(`spreadsheets/concatenate-sheets`, {
            method: "POST",
        }).then(response => {
            if (!response.ok) {
                throw new Error("An error occurred");
            }
            return Promise.resolve();
        })

        toast.promise(concatenatePromise, {
            pending: 'Concatenating...',
            success: 'Successfully 🎉',
            error: 'An error occurred 🤯'
        }).finally(() => {
            setLoading(false)
        })
    }

    function checkResultSheetAccess(sheet) {
        if (sheet.link && sheet.spreadSheetName) {
            const resultSheetTimeoutKey = "result";
            // validate sheet
            if (timeoutRef.current.has(resultSheetTimeoutKey)) {
                clearTimeout(timeoutRef.current.get(resultSheetTimeoutKey));
            }

            timeoutRef.current.set(resultSheetTimeoutKey,
                setTimeout(() => {

                    setResultSheet(prev => ({
                        ...prev,
                        accessibilityStatus: accessibilityStatus.checking
                    }));

                    fetch("spreadsheets/check-access", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(sheet)
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        }

                        setResultSheet(prev => ({
                            ...prev,
                            accessibilityStatus: accessibilityStatus.invalid
                        }));

                    }).then((result) => {

                        setResultSheet(prev => ({
                            ...prev,
                            accessibilityStatus: result ? accessibilityStatus.valid : accessibilityStatus.invalid
                        }));
                    });
                }, 500)
            );
        }
    }
    function checkSourceSheetAccess(sheet) {
        if (sheet.link && sheet.spreadSheetName) {
            // validate sheet
            const index = sheets.indexOf(sheet);
            if (timeoutRef.current.has(index)) {
                clearTimeout(timeoutRef.current.get(index));
            }

            // each item has one corresponding timeout
            timeoutRef.current.set(index,
                setTimeout(() => {

                    sheets[index].accessibilityStatus = accessibilityStatus.checking;
                    setSheets([...sheets]);

                    fetch("spreadsheets/check-access", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(sheet)
                    }).then((response) => {
                        if (response.ok) {
                            return response.json();
                        }

                        setSheets(prevSheets => {
                            prevSheets[prevSheets.indexOf(sheet)].accessibilityStatus = accessibilityStatus.invalid;

                            return [...prevSheets];
                        })

                    }).then((result) => {
                        setSheets(prevSheets => {
                            console.log("result", result)
                            prevSheets[prevSheets.indexOf(sheet)].accessibilityStatus = result ? accessibilityStatus.valid : accessibilityStatus.invalid;

                            return [...prevSheets];
                        })
                    });
                }, 500)
            );
        }
    }

    return (
        <>
            <Container fluid className={"pb-5"}>
                <Row>
                    <Col>

                        {!isComputeSheet &&
                            <Row>
                                <Col>
                                    <Button disabled={isLoading} variant="primary" onClick={handleConcatenate}>Start
                                        Concatenate</Button>
                                </Col>
                                <Col>
                                    <h3>Last at: <i style={{ color: "forestgreen" }}>{
                                        resultSheet?.calculatedAt ? (
                                            new Intl.DateTimeFormat("vi-VN", {
                                                year: "numeric",
                                                month: "numeric",
                                                day: "numeric",
                                                hour: "numeric",
                                                minute: "numeric",
                                                second: "numeric",
                                                hour12: false,
                                                timeZone: "Asia/Ho_Chi_Minh",
                                            }
                                            ).format(new Date(resultSheet?.calculatedAt))
                                        ) : null
                                    }</i></h3>
                                </Col>
                            </Row>

                        }
                        <h1>Add Sheets ({isComputeSheet ? "Computation" : "Concatenation"})</h1>
                        {sheets.map((sheet, index) => (
                            <Row key={index}>
                                <Col>
                                    <InputGroup className="mb-3">
                                        <Form.Control disabled={isComputeSheet && sheet.status === "Stopped"}
                                            placeholder="Enter Sheet Link" value={sheet?.link || ""}
                                            onChange={(e) => {
                                                sheets[index].link = e.target.value
                                                setSheets([...sheets])
                                                checkSourceSheetAccess(sheets[index]);
                                            }} />
                                        <Form.Control disabled={isComputeSheet && sheet.status === "Stopped"}
                                            placeholder="Enter Sheet Name"
                                            value={sheet?.spreadSheetName || ""} onChange={(e) => {
                                                sheets[index].spreadSheetName = e.target.value
                                                setSheets([...sheets])
                                                checkSourceSheetAccess(sheets[index])
                                            }} />
                                    </InputGroup>

                                </Col>
                                <Col className="d-flex align-items-start" style={{ gap: "8px" }} md={2}>
                                    {sheets.length > 1 && (
                                        <Button variant="outline-danger"
                                            onClick={() => handleRemoveSheet(index)}>-</Button>
                                    )}
                                    {index === sheets.length - 1 && (
                                        <Button variant="outline-primary" onClick={handleAddMoreSheet}>+</Button>
                                    )}
                                    {sheets[index].accessibilityStatus === accessibilityStatus.checking && (
                                        <Button variant="outline-light" disabled>
                                            <Spinner
                                                as="span"
                                                variant="primary"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </Button>)
                                    }
                                    {sheets[index].accessibilityStatus === accessibilityStatus.valid && (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    Sheet is accessible
                                                </Tooltip>
                                            }>
                                            <div>
                                                <Button title="test" variant="link" disabled>
                                                    <BsCheckAll style={
                                                        {
                                                            fill: "green",
                                                            width: "20px",
                                                            height: "20px"
                                                        }
                                                    } />
                                                </Button>
                                            </div>
                                        </OverlayTrigger>
                                    )
                                    }
                                    {sheets[index].accessibilityStatus === accessibilityStatus.invalid && (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    Sheet is not accessible
                                                </Tooltip>
                                            }
                                            message="Cannot access the sheet"
                                        >
                                            <div>
                                                <Button title="test" variant="link" disabled>
                                                    <IoIosWarning style={{
                                                        fill: "red",
                                                        width: "20px",
                                                        height: "20px"
                                                    }} />
                                                </Button>
                                            </div>
                                        </OverlayTrigger>
                                    )
                                    }
                                </Col>
                            </Row>
                        ))}
                    </Col>

                </Row >

                {< Button disabled={isLoading} variant="success" onClick={() => handleSubmitSourceSheets(sheets)
                }>
                    {isLoading ? "Loading…" : "Submit"}
                </Button >}

                <Row>
                    <Col>
                        <h1>Change Result Sheet ({isComputeSheet ? "Computation" : "Concatenation"}) </h1>
                        <InputGroup className="mb-3">
                            <Form.Control placeholder="Enter Sheet Link" value={resultSheet?.link} onChange={(e) => {
                                setResultSheet(prevResultSheet => ({ ...prevResultSheet, link: e.target.value }));
                            }} />
                            <Form.Control placeholder="Enter Sheet Name" value={resultSheet?.spreadSheetName}
                                onChange={(e) => {
                                    const newResultSheet = { ...resultSheet, spreadSheetName: e.target.value }
                                    setResultSheet(newResultSheet);

                                    checkResultSheetAccess(newResultSheet);
                                }} />


                            <div className="d-flex align-items-start" style={{ gap: "8px" }} md={2}>
                                {resultSheet.accessibilityStatus === accessibilityStatus.checking && (
                                    <Button variant="outline-light" disabled>
                                        <Spinner
                                            as="span"
                                            variant="primary"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </Button>)
                                }
                                {resultSheet.accessibilityStatus === accessibilityStatus.valid && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id={`tooltip-result-sheet`}>
                                                Result sheet is accessible
                                            </Tooltip>
                                        }>
                                        <div>
                                            <Button title="test" variant="link" disabled>
                                                <BsCheckAll style={
                                                    {
                                                        fill: "green",
                                                        width: "20px",
                                                        height: "20px"
                                                    }
                                                } />
                                            </Button>
                                        </div>
                                    </OverlayTrigger>
                                )
                                }
                                {resultSheet.accessibilityStatus === accessibilityStatus.invalid && (
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id={`tooltip-result-sheet`}>
                                                Result sheet is not accessible
                                            </Tooltip>
                                        }
                                        message="Cannot access the sheet"
                                    >
                                        <div>
                                            <Button title="test" variant="link" disabled>
                                                <IoIosWarning style={{
                                                    fill: "red",
                                                    width: "20px",
                                                    height: "20px"
                                                }} />
                                            </Button>
                                        </div>
                                    </OverlayTrigger>
                                )
                                }
                            </div>
                        </InputGroup>
                    </Col>




                </Row>

                {
                    <Button disabled={isLoading} variant="success" onClick={() => handleSubmitResultSheet()}>
                        {isLoading ? "Loading…" : "Submit"}
                    </Button>
                }
            </Container >
        </>
    )
}