import {Button, Table, Badge} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";

export default function SheetsTable() {
    const [spreadsheets, setSpreadsheets] = useState([]);
    const [isLoading, setLoading] = useState(false);

    const fetchSpreadsheets = () => {
        setLoading(true);

        fetch("spreadsheets/all-sheets?isComputeSheet=true")
            .then((response) => response.json())
            .then((data) => setSpreadsheets(data))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchSpreadsheets();
    }, []);

    const handleSyncAll = () => {
        setLoading(true);
        const syncAllPromise = fetch("spreadsheets/sync-all", {
            method: "POST",
        }).then(response => {
            if (!response.ok) {
                throw new Error("An error occurred");
            }

            return Promise.resolve();
        })

        toast.promise(syncAllPromise, {
            pending: 'Syncing...',
            success: 'Sync successfully 🎉',
            error: 'An error occurred 🤯'
        })
            .finally(() => {
                fetchSpreadsheets()
                setLoading(false);
            });
    };

    const handleStopSync = (spreadSheetId, spreadSheetName) => {
        setLoading(true);
        const stopSyncPromise = fetch(`spreadsheets/stop-sync/?sheetId=${spreadSheetId}&sheetName=${spreadSheetName}`, {
            method: "POST",
        }).then(response => {
            if (!response.ok) {
                throw new Error("An error occurred");
            }

            return Promise.resolve();
        });

        toast.promise(stopSyncPromise, {
            pending: 'Stopping...',
            success: 'Sync stopped successfully 🎉',
            error: 'An error occurred 🤯'
        })
            .finally(() => {
                setLoading(false);
                handleSyncAll();
            })

    };

    const sourceSheets = spreadsheets.filter(x => x.type === "Source");
    const resultSheet = spreadsheets.filter(x => x.type === "Result")[0];

    return (
        <>
            <Button variant="primary" disabled={isLoading} onClick={handleSyncAll}>
                {isLoading ? "Loading…" : "Sync Sheets"}
            </Button>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Link</th>
                    <th>Sheet Name</th>
                    <th>Status</th>
                    <th>Last Calculated At</th>
                </tr>
                </thead>
                <tbody>
                {sourceSheets.map((item, index) => (
                    <tr key={`${item.spreadSheetId}_${item.spreadSheetName}`}>
                        <td>
                            <a href={item.link}>{item.spreadSheetId}</a>
                        </td>
                        <td>{item.spreadSheetName}</td>
                        <td>
                            <Badge bg={item.status === "Active" ? "success" : "secondary"}>{item.status}</Badge>
                        </td>
                        <td>{
                            resultSheet?.calculatedAt ? new Intl.DateTimeFormat("vi-VN", {

                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                    hour12: false,
                                    timeZone: "Asia/Ho_Chi_Minh",
                                }
                            ).format(new Date(resultSheet.calculatedAt)) : ""
                        }</td>
                        {item.status === "Active" ? (
                            <td>
                                <Button
                                    disabled={isLoading}
                                    onClick={() => {
                                        handleStopSync(item.spreadSheetId, item.spreadSheetName);
                                    }}
                                    variant="danger"
                                >
                                    {isLoading ? "Loading…" : "Stop Sync"}
                                </Button>
                            </td>
                        ) : null}
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    )
}